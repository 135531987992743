
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import _cloneDeep from 'lodash.clonedeep'
import { Customer } from '@/models/dto/Customer'
import { formatFullName, htmlToString } from '@/utils/string'
import dayjs from 'dayjs'
import quote from '@/store/modules/quote'
import companyClient from '@/services/company'
import customerAccountClient from '@/services/customerAccount'
import customerClient from '@/services/customer'
import { EmailTemplate, ReplyToEmailOption } from '@/models/dto/Template'
import { Email } from '@/models/dto/Email'
import { Company } from '@/models/dto/Company'
import { CustomerAccountDetailEntity } from '@/models/dto/CustomerAccount'
import client from '@/services/quotes'

@Component({})
export default class CustomEmailSidebar extends Vue {
  @Prop({ required: true, default: null }) link: string
  @Prop({ required: true, default: null }) id: string
  @Prop({ required: true, default: null }) pdfType: string
  @Prop({ required: true, default: null }) title: string
  @Prop({ required: true, default: null }) templates: EmailTemplate[]
  @Prop({ required: false, default: null }) customers: Customer[]
  @Prop({ required: false, default: null }) bookingContact: Customer
  @Prop({ required: false, default: null }) billingContact: Customer
  @Prop({ type: Boolean, default: false }) showBackBtn: boolean
  @Prop({ type: Boolean, default: false }) isQuote: boolean
  @Prop({ type: Boolean, default: false }) useAlternateSender: boolean

  formatFullName = formatFullName

  email: Email = new Email()
  recipients: Customer[] = []
  company: Company = null
  customerAccount: CustomerAccountDetailEntity = null
  fullCustomer: Customer = null
  fullBillingCustomer: Customer = null
  linkModal = false
  linkText = ''
  isSubmitting = false
  showSendAsInvoiceToggle = false
  loadedCustomers = []
  selectedEmailOption = null

  additionalActions = [
    {
      label: 'Send As Invoice',
      event: (): void => this.displaySendAsInvoiceToggle(),
    },
  ]

  get customersList(): Customer[] {
    return this.customers === null ? this.loadedCustomers : this.customers
  }

  get pdfTitle(): string {
    if (this.pdfType === 'Quote' && this.sendAsInvoice) {
      return 'Invoice'
    }
    return this.pdfType
  }

  get sendAsInvoice(): boolean {
    return quote?.quote?.sendAsInvoice || false
  }

  get suggestTemplates(): boolean {
    return (
      !htmlToString(this.email?.body || '') || !!this.filteredTemplates.length
    )
  }

  get pdfName(): string {
    const dateFormat = auth.shouldDisplayDayLeading ? 'D.M.YY' : 'M.D.YY'
    return `${auth.user.companyName} ${this.pdfTitle} - ${
      this.contactName
    } ${dayjs().format(dateFormat)}`
  }

  get filteredTemplates(): EmailTemplate[] {
    const body = htmlToString(this.email?.body || '').toLowerCase()
    if (body.length > 16) {
      return []
    }
    return this.templates?.filter((template) => {
      const text = this.templateText(template).toLowerCase()
      return text !== body && text.includes(body)
    })
  }

  displaySendAsInvoiceToggle(): void {
    this.showSendAsInvoiceToggle = true
    this.updateQuoteSendAsInvoice(true)
  }

  templateText(template: EmailTemplate): string {
    return htmlToString(template?.opener || '')
  }

  handleRecipientsChange(recipients: (Customer | string)[]): void {
    this.recipients = (recipients || []).map((recipient) =>
      typeof recipient === 'string'
        ? new Customer({ firstName: recipient, email: recipient })
        : recipient
    )
    this.email = { ...this.email, to: this.recipients.map((c) => c.email) }
  }

  handleSelectTemplate(template: EmailTemplate): void {
    this.email = {
      ...this.email,
      subject: this.replaceTemplateData(template.subject),
      body: this.replaceTemplateData(template.opener),
    }
  }

  removeEmailContact(customer: Customer): void {
    this.recipients = (this.recipients || []).filter((el) => {
      return customer.customerId
        ? el.customerId !== customer.customerId
        : el.email !== customer.email
    })
    this.email = { ...this.email, to: this.recipients.map((c) => c.email) }
  }

  handleChangeTemplateText(body: string): void {
    this.email = { ...this.email, body }
  }

  send(): void {
    this.isSubmitting = true
    if (this.selectedEmailOption.replyToEmail) {
      this.email.from = this.selectedEmailOption.replyToEmail
    }

    if (this.selectedEmailOption.replyToEmailName) {
      this.email.fromName = this.selectedEmailOption.replyToEmailName
    }
    this.$emit('send', this.email)
  }

  insertLink(): void {
    const ref: any = this.$refs['custom-email-richtext']
    this.linkText = ref.editor.state.doc.textBetween(
      ref.editor.state.selection.from,
      ref.editor.state.selection.to,
      ' '
    )
    this.linkModal = true
  }

  saveLink(): void {
    const ref: any = this.$refs['custom-email-richtext']
    const link = `<a href="${this.link}" target="_blank">${this.linkText}</a>`
    ref.editor.commands.insertContent(link)
    this.linkModal = false
  }

  closelinkModal(): void {
    this.linkText = ''
    this.linkModal = false
  }

  attachPDF(): void {
    this.email = { ...this.email, includePdf: true }
  }

  removePDF(): void {
    this.email = { ...this.email, includePdf: false }
  }

  close(): void {
    sidebar.pop()
  }

  get fromEmail(): string {
    if (this.company?.emailSenderName) {
      return `${this.company?.emailSenderName} (${this.email.from})`
    }
    return this.email.from
  }

  get contactName(): string {
    const contactNames: string[] = []

    const primaryContact = this.bookingContact?.firstName
    if (primaryContact) {
      contactNames.push(primaryContact)
    }

    const secondaryContact = this.billingContact?.firstName
    if (secondaryContact) {
      contactNames.push(secondaryContact)
    }

    return contactNames.join(' and ')
  }

  get defaultRecipients(): Customer[] {
    const recipients: Customer[] = []

    const bookingContact = this.bookingContact
    if (bookingContact) {
      recipients.push(bookingContact)
    }

    const billingContact = this.billingContact
    if (billingContact) {
      recipients.push(billingContact)
    }

    return recipients
  }

  setShowSendAsInvoiceToggle(): boolean {
    if (
      this.isQuote &&
      (this.fullCustomer?.sendQuotesAsInvoice ||
        this.fullBillingCustomer?.sendQuotesAsInvoice ||
        this.customerAccount?.sendQuotesAsInvoice ||
        this.sendAsInvoice)
    ) {
      this.showSendAsInvoiceToggle = true
      return
    }
    this.showSendAsInvoiceToggle = false
    return
  }

  get defaultSubject(): string {
    return this.templates?.length
      ? this.replaceTemplateData(this.templates[0].subject)
      : ''
  }

  get defaultBody(): string {
    return this.templates?.length
      ? this.replaceTemplateData(this.templates[0].opener)
      : ''
  }

  get emailOptions(): ReplyToEmailOption[] {
    const companyEmailOption = {
      data: {
        replyToEmail: auth.getCompany?.email || '',
        replyToEmailName: auth.getCompany?.emailSenderName || '',
      },
      displayText: '',
    }
    companyEmailOption.displayText = `${companyEmailOption.data.replyToEmailName} (${companyEmailOption.data.replyToEmail})`

    if (!this.useAlternateSender) {
      return [companyEmailOption]
    }

    const userEmailOption = {
      data: {
        replyToEmail: auth.getUser?.email || '',
        replyToEmailName:
          auth.getUser.emailSenderName ||
          `${auth.getUser.firstName} ${auth.getUser.lastName}`,
      },
      displayText: '',
    }
    userEmailOption.displayText = `${userEmailOption.data.replyToEmailName} (${userEmailOption.data.replyToEmail})`
    return [companyEmailOption, userEmailOption]
  }

  get defaultIncludePdf(): boolean {
    return this.templates?.[0]?.includePdf || false
  }

  async getCompany(): Promise<void> {
    const company = await companyClient
      .byId(auth.getCompanyId)
      .then((response) => response.data.company)
    this.company = company
  }

  async getCustomerAccount(): Promise<void> {
    if (!quote?.quote?.customerAccountId) {
      return null
    }
    const customerAccount = await customerAccountClient
      .byId(quote.quote.customerAccountId, { slim: true })
      .then((response) => response.data)
    this.customerAccount = customerAccount
  }

  async getFullCustomer(): Promise<void> {
    if (!this.bookingContact || !this.bookingContact.customerId) {
      return null
    }
    const fullCustomer = await customerClient
      .byId(this.bookingContact.customerId)
      .then((response) => response.data.customer)
    this.fullCustomer = fullCustomer
  }

  async getFullBillingCustomer(): Promise<void> {
    if (!this.billingContact || !this.billingContact.customerId) {
      return null
    }
    const fullBillingCustomer = await customerClient
      .byId(this.billingContact.customerId)
      .then((response) => response.data.customer)
    this.fullBillingCustomer = fullBillingCustomer
  }

  async updateQuoteSendAsInvoice(sendAsInvoice: boolean): Promise<void> {
    try {
      await client.updateSendQuoteAsInvoice({
        quoteId: quote.quote.quoteId,
        sendAsInvoice,
      })
      quote.updateQuote({ sendAsInvoice })
    } catch (e) {
      console.log(e)
    }
  }

  replaceTemplateData(template: string): string {
    const email = auth.getUser.email
    return _cloneDeep(
      template
        .replaceAll('{{ CONTACT_FIRST_NAME }}', this.contactName)
        .replaceAll('{{ SENDER_FULL_NAME }}', auth.getFullName)
        .replaceAll('{{ SENDER_FIRST_NAME }}', auth.getUser.firstName)
        .replaceAll('{{ QUOTE_ID }}', this.id)
        .replaceAll('{{ RESERVATION_ID }}', this.id)
        .replaceAll('{{ QUOTE_LINK }}', this.link)
        .replaceAll('{{ INVOICE_LINK }}', this.link)
        .replaceAll(
          '{{ SENDER_EMAIL }}',
          `<a href="mailto:${email}">${email}</a>`
        )
    )
  }

  async getCustomers(): Promise<void> {
    const res = await customerClient.tableView({ pageSize: -1 })
    this.loadedCustomers = res.data.resultList
  }

  async created(): Promise<void> {
    try {
      this.recipients = this.defaultRecipients
      let customerTVPromise = null
      if (this.customers === null) {
        customerTVPromise = this.getCustomers()
      }
      const companyPromise = this.getCompany()
      const customerAccountPromise = this.getCustomerAccount()
      const customerPromise = this.getFullCustomer()
      const billingCustomerPromise = this.getFullBillingCustomer()
      await Promise.all([
        companyPromise,
        customerAccountPromise,
        customerPromise,
        billingCustomerPromise,
        customerTVPromise,
      ])
      this.setShowSendAsInvoiceToggle()

      this.selectedEmailOption = this.emailOptions.find(
        (option) =>
          option.data.replyToEmail === this.templates[0].replyToEmail &&
          option.data.replyToEmailName === this.templates[0].replyToEmailName
      )
      if (!this.selectedEmailOption) {
        this.selectedEmailOption = this.emailOptions[0]
      }
      this.email = {
        to: this.recipients.map((r) => r.email),
        from: this.selectedEmailOption.data.replyToEmail,
        fromName: this.selectedEmailOption.data.replyToEmailName,
        subject: this.defaultSubject,
        body: this.defaultBody,
        includePdf: this.defaultIncludePdf,
      }
    } catch (e) {
      console.log(e)
    }
  }
}
