export interface Email {
  to: string[]
  from: string
  fromName: string
  subject: string
  body: string
  includePdf: boolean
}

// eslint-disable-next-line no-redeclare
export class Email implements Email {
  constructor(email?: Partial<Email>) {
    Object.assign(this, email)
  }
}
