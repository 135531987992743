
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import op from 'simple-object-path'

@Component({})
export default class CUDataTableLink extends Vue {
  @Prop({ required: true }) readonly row!: Record<string, unknown>
  @Prop({ required: true }) readonly column!: DataTableColumn
  EventBus = EventBus

  get label(): string {
    if (this.column.computedText) {
      return this.column.computedText(this.row)
    }
    return op(this.row, this.column.value)
  }
}
