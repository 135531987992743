export interface EmailTemplate {
  emailCustomizationId: number
  companyId: number
  includePdf: boolean
  subject: string
  replyToEmail: string
  replyToEmailName: string
  opener: string
  closing: string
  userAction: string
  checkoutText: string
}

// eslint-disable-next-line no-redeclare
export class EmailTemplate implements EmailTemplate {
  constructor(emailTemplate?: Partial<EmailTemplate>) {
    Object.assign(this, emailTemplate)
  }
}

export interface ReplyToEmailOption {
  data: {
    replyToEmail: string
    replyToEmailName: string
  }
  displayText: string
}
